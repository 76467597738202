<template>
  <div class="qr-code">
    <Voltar />
    <h1 class="titulo">Ticket Impresso</h1>

    <form class="form" @submit.prevent="handleSubmit">
      <div>
        <label for="nome">DIGITE O CÓDIGO</label>
        <input type="number" max="999999" maxlength="6" v-model="state.codigo.value" required />
      </div>

      <div class="bt bt--destaque">
        <button type="submit" :disabled="state.isLoading">Registrar Código</button>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useField } from 'vee-validate'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    const router = useRouter()
    const toast = useToast()
    const user = JSON.parse(window.localStorage.getItem('user'))

    const { value: codigo, errorMessage: codigoErrorMessage } =
      useField('codigo')

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      codigo: {
        value: codigo,
        errorMessage: codigoErrorMessage
      }
    })

    async function handleSubmit () {
      try {
        state.isLoading = true
        const { data, errors } = await services.pagamentos.cupom({
          code: state.codigo.value,
          token: user.token
        })

        if (!errors && !data.errors) {
          user.assinatura = data
          window.localStorage.setItem('user', JSON.stringify(user))
          router.push({ name: 'Sucesso' })
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401 || errors.status === 400 || errors.status === 422) {
          toast.error(errors.statusText)
        }

        state.isLoading = false
      } catch (error) {
        if (error != null) {
          if (error.status === 404 || error.status === 401 || error.status === 400 || error.status === 422) {
            toast.error('Ticket não encontrado ' + error.statusText)
          }
          console.log('error...', error)
          console.log(error.status)
          toast.error('Erro ao verificar o Ticket!')
          state.isLoading = false
          state.hasErrors = !!error
        }
      }
    }

    return {
      state,
      handleSubmit,
      user
    }
  },
  components: { Voltar }
}
</script>
